import React, { type ReactNode, useEffect, useState } from 'react';
import { isNil, isEqual } from 'lodash';
import { type IUserData, TelemetryWrapper } from '@ayx/ui-telemetry';

import { useAuth } from 'stores/auth';
import getEnvironmentVariable from 'utils/getEnvVariable';

export default function Telemetry({ children }: { children: ReactNode }) {
	const { isAuthenticated, session } = useAuth();
	const [authedUser, setAuthedUser] = useState<IUserData | undefined>(
		undefined
	);

	useEffect(() => {
		const { user } = session;
		if (isAuthenticated() && !isNil(user)) {
			const newUserInfo: IUserData = {
				internalUser: user.email.includes('@alteryx.com'),
				locale: user.preferredLanguage ?? undefined,
				// Based on guidance from Felipe from the Analytics team, ID is not needed and userData.email is NOT deprecated
				// For now we will send email and id as the same value to avoid breaking changes
				email: user.email,
				id: user.email,
			};
			if (!isEqual(newUserInfo, authedUser)) {
				setAuthedUser(newUserInfo);
			}
		}
	}, [isAuthenticated, session, authedUser]);
	return (
		<TelemetryWrapper
			getProductInfo={() => ({
				version: getEnvironmentVariable('REACT_APP_VERSION'),
			})}
			productId={getEnvironmentVariable('REACT_APP_TELEMETRY_KEY')}
			userInfo={authedUser}
			environment={getEnvironmentVariable(
				'REACT_APP_TELEMETRY_ENVIRONMENT'
			)}
		>
			{children}
		</TelemetryWrapper>
	);
}
