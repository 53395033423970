import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@ayx/onyx-ui';
import { Trans } from 'react-i18next';
import {
	GenericPage,
	useEffectOnce,
} from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { errors, paths } from 'enums';
import { ReactComponent as IllustrationLockSvg } from 'assets/images/illustration-lock.svg';

function UnauthorizedExternalUserPage({ redirectTo }: { redirectTo: string }) {
	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();

	useEffectOnce(() => {
		// if a user is authed, prevent them from coming back to this page
		if (isAuthenticated()) {
			navigate(redirectTo, { replace: true });
		}
	});

	return (
		<GenericPage
			icon={<IllustrationLockSvg width={208} />}
			title="Unauthorized"
			text={errors.UNAUTHORIZED_EXTERNAL}
			actions={
				<Button
					data-cy="unauthorized-logout-button"
					variant="contained"
					color="primary"
					className="w-32"
					component={Link}
					to={paths.logout}
					replace
				>
					<Trans>Sign Out</Trans>
				</Button>
			}
		/>
	);
}

export default UnauthorizedExternalUserPage;
