import getEnvironmentVariable from 'utils/getEnvVariable';

type Environment = 'LCL' | 'DEV' | 'UAT' | 'PRO';

const getEnvironment = (): Environment => {
	const apiBase = getEnvironmentVariable('REACT_APP_API_BASE');
	if (apiBase.includes('localhost')) {
		return 'LCL';
	}
	if (apiBase.includes('dev')) {
		return 'DEV';
	}
	if (apiBase.includes('uat') || apiBase.includes('test')) {
		return 'UAT';
	}
	return 'PRO';
};

export default getEnvironment;
