import { getCaseUrl } from 'utils/getEnvDependentUrl';

const paths = {
	// utility
	root: '/',
	wildcard: '*',

	// errors
	notFound: '/404',
	error: '/error',
	unauthorizedExternalUser: '/unauthorized',
	unauthorized: '/login',

	// auth utilities
	authorize: '/authorize',
	logout: '/logout',

	// pages
	viewer: '/file-viewer',
	version: '/version',
	myFiles: '/my-files',

	// external
	external: {
		case: (caseId: string) => getCaseUrl(caseId),
	},
};

export default paths;
