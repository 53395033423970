import React, { type ReactNode } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import {
	AppBar,
	Box,
	Container,
	Divider,
	Grid,
	Link,
	Toolbar,
} from '@ayx/onyx-ui';
import {
	Typography,
	UserProfileMenu,
} from '@automation-analytics/component-library';

import { useAuth } from 'stores/auth';
import { paths } from 'enums';
import alteryxLogo from 'assets/images/alteryx-logo.svg';
import type { User } from 'types';

export const TOP_NAV_MIN_HEIGHT = `44px`;

const fullWidthPages = [
	new RegExp(`${paths.viewer}/\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}/?`),
];

function AuthedNavActions({ user }: { user: NonNullable<User> }) {
	return (
		<div className="flex items-center">
			<UserProfileMenu user={user} />
		</div>
	);
}

function GlobalWrapper({ children }: { children: ReactNode }) {
	const location = useLocation();
	const { isAuthenticated, session } = useAuth();
	const isFullWidth = fullWidthPages.some((re) => re.test(location.pathname));

	return (
		<Box className="h-screen min-h-screen flex flex-col justify-between">
			<AppBar
				color="default"
				position="static"
				elevation={3}
				className="z-50"
			>
				<Toolbar variant="dense" className="shadow-app-bar px-3">
					<Grid
						alignItems="center"
						container
						justifyContent="space-between"
						className={`py-[5px] min-h-[${TOP_NAV_MIN_HEIGHT}] gap-2`}
					>
						<div className="flex flex-col sm:flex-row gap-x-5 items-start sm:items-center">
							<Link
								component={RouterLink}
								to={paths.myFiles}
								className="h-6"
							>
								<img
									src={alteryxLogo}
									className="h-full"
									alt="Alteryx"
								/>
							</Link>
							<Divider
								orientation="vertical"
								role="presentation"
								flexItem
								className="bg-divider invisible sm:visible"
							/>
							<Typography
								variant="body1"
								className="font-montserrat font-normal text-base max-w-[200px] sm:max-w-none leading-tight py-1"
								skipTranslation
							>
								Tech Support File&nbsp;Viewer
							</Typography>
						</div>
						{isAuthenticated() && (
							// session.user will never be null here
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							<AuthedNavActions user={session.user!} />
						)}
					</Grid>
				</Toolbar>
			</AppBar>
			<Container
				maxWidth={isFullWidth ? false : 'xl'}
				className={
					isFullWidth
						? 'px-0 h-full'
						: 'md:px-0 py-4 px-8 md:py-16 h-full'
				}
			>
				{children}
			</Container>
		</Box>
	);
}

export default GlobalWrapper;
