import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'stores/auth';
import { paths } from 'enums';

interface RequireAuthProps {
	redirectTo?: string;
}

function RequireAuth({ redirectTo = paths.unauthorized }: RequireAuthProps) {
	const { isAuthenticated } = useAuth();

	return isAuthenticated() ? (
		<Outlet />
	) : (
		<Navigate to={redirectTo as string} />
	);
}

export default RequireAuth;
