import getEnvironment from 'utils/getEnvironment';

const casePortalUrls = {
	uat: 'https://alteryx--ayxuat.sandbox.my.site.com/CustomerCasePortal/s/',
	pro: 'https://alteryx.my.site.com/CustomerCasePortal/s/customercases/',
};

const getCaseUrl = (caseId: string) => {
	const env = getEnvironment();
	switch (env) {
		case 'PRO':
			return `${casePortalUrls.pro}?id=${caseId}`;
		default:
			return `${casePortalUrls.uat}?id=${caseId}`;
	}
};

// eslint-disable-next-line import/prefer-default-export
export { getCaseUrl };
